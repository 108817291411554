import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import { getOrgs } from "src/apis/getOrgs"
import { postUserOrgRole } from "src/apis/postUserOrgRole"
import { GetOrgsInput, Org, OrgType } from "src/types/orgs"
import { Role, UserOrgRole } from "src/types/users"

interface RoleModalProps {
  onClose: (shouldRefresh: boolean)=>void
  refreshTable: () => void
  orgRole: UserOrgRole | undefined
  orgIdsToExclude: Set<string>
  userEmail: string
}

const RoleModal: React.FC<RoleModalProps> = ({userEmail, orgRole, orgIdsToExclude, onClose, refreshTable}) => {
  const [validationError, setValidationError] = useState<string>('')
  const [orgId, setOrgID] = useState(orgRole !== undefined ? orgRole.orgId : "")
  const handleOrgChange = (event: SelectChangeEvent) => {
    setOrgID(event.target.value)
  }
  // only support setting a single role per org for now...
  const [role, setRole] = useState(orgRole !== undefined ? orgRole.roles[0] : "")
  const handleRoleChange = (event:SelectChangeEvent) => {
    setRole(event.target.value as Role);
  };
  const [orgs, setOrgs] = useState<Org[]>([])
  const closeWithoutRefresh = () =>  {
    onClose(false)
  }

  // fetch the orgs that we have to choose from
  useEffect(()=> {
    const fetchOrgs = async () => {
      const input = {
        type: OrgType.Merchant,
        limit: 100 // if there's more than this, we probably need something akin to a search field here...
      } as GetOrgsInput
      try {
        const res = await getOrgs(input)
        if (res && res.status === 200) {
          // set the orgs in the dropdown to the orgs from the backend WITHOUT the orgs the user already has a role in.
          setOrgs(res.data.orgs.filter(org => {
            if (orgIdsToExclude.has(org.id)) {
              return false
            } else {
              return true
            }
          }))
        }
      } catch (error) {
        console.error('failed to get users: ', error)
      }
    }
    fetchOrgs()
  }, [orgIdsToExclude])

  const handleSubmit = async () => {
    // ensure we have a properly formatted email before we call the apis.
    if (orgId === "" || role === "") {
      setValidationError('please select an org and a role')
      return
    }

    const newOrgRole = {
      userEmail: userEmail,
      orgId: orgId,
      roles: [role]
    } as UserOrgRole

    // attempt to call the API.
    try {
      const res = await postUserOrgRole(newOrgRole)
      if (res && res.status === 201) {
        onClose(true)
      }
    } catch (error) {
      console.error('failed to post user org role: ', error)
      alert('There was an error posting the user org role. Please try again.')
    }
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <h2 className="modal-header">{'User Role'}</h2>
        {/* only render the org field if we're creating a brand new org role. */}
        {orgRole === undefined && 
        <FormControl fullWidth>
          <InputLabel>Org</InputLabel>
          <Select
              value={orgId}
              onChange={handleOrgChange}
          >
          {orgs.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                  {org.influencerHandle === undefined ? org.id : org.influencerHandle}
              </MenuItem>
          ))}
          </Select>
        </FormControl>
        }
        <FormControl fullWidth>
          <InputLabel>Role</InputLabel>
          <Select
              value={role}
              onChange={handleRoleChange}
          >
          {Object.values(Role).map((role) => (
              <MenuItem key={role} value={role}>
                  {role}
              </MenuItem>
          ))}
          </Select>
        </FormControl>
        {validationError && <span style={{color: 'red'}}>{validationError}</span>}
        {/* Create and Cancel Buttons */}
        <div className="modal-actions">
          <Button className="create-user-button" variant="contained" onClick={handleSubmit}>
            {'Submit Org Role'}
          </Button>
          <Button className="cancel-user-button" onClick={closeWithoutRefresh}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RoleModal