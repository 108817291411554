import { Image } from '@aws-amplify/ui-react';
import { Button, FormControl, IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUsers } from 'src/apis/getusers';
import {deleteIcon, editIcon} from "../../../utils/logos"
import { GetUsersParams, PatchUserPayload, User, UserOrgRole } from 'src/types/users';
import { patchUser } from 'src/apis/patchuser';
import { deleteUserOrgRole } from 'src/apis/deleteUserOrgRole';
import RoleModal from './RoleModal';

const EditUser = () => {
  // dummy state object to trigger a refresh based on operations in the modal. 
  // not sure if there's a better way, but this works.
  const [refresh, setRefresh] = useState<boolean>(false)
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<User>({} as User)
  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [orgsWithRole, setOrgsWithRole] = useState<Set<string>>(new Set())
  const [isRoleModalOpen, setIsRoleModalOpen] = useState<boolean>(false)
  const [selectedOrgRole, setSelectedOrgRole] = useState<UserOrgRole | undefined>(undefined)

  const closeRoleModal = (shouldRefresh: boolean) => {
    setIsRoleModalOpen(false)
    setSelectedOrgRole(undefined)
    if (shouldRefresh) {
      triggerPageRefresh()
    }
  }

  const triggerPageRefresh = () => {
    setRefresh(!refresh)
  }
  
  // get the initial user.
  useEffect(() => {
    const fetchUser = async () => {
      const params = {
        userIds: userId,
        limit: 25,
        offset: 0,
      } as GetUsersParams
      try {
        const response = await getUsers(params)
        if (response && response.data && response.data.length === 1) {
          const userResp = response.data[0]
          setUser(userResp)
          // also set the orgs that this user has a role for
          const newSet = new Set<string>()
          for (let i = 0; i < userResp.orgsAndRoles.length; i++) {
            newSet.add(userResp.orgsAndRoles[i].orgId)
          }
          setOrgsWithRole(newSet)
        }
      } catch(error) {
        console.error('Error fetching user:', error)
      }
    }

    fetchUser()
  },[userId, refresh])

  // update first and last name
  useEffect(() => {
    setFirstName(user.firstName)
    setLastName(user.lastName)
  }, [user]) 

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let firstName = event.target.value

    setFirstName(firstName)
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let lastName = event.target.value

    setLastName(lastName )
  }

  const handleResetPasswordClick = async () => {
    const payload = {
      firstName: user.firstName,
      lastName: user.lastName,
      resetPassword: true
    } as PatchUserPayload

    try {
      const response = await patchUser(user.id, payload)
      if (response && response.status === 200) {
        triggerPageRefresh()
        window.alert('successfully reset password')
      } else {
        window.alert('failed to reset password')
      }
    } catch (error) {
      console.error('failed to reset user password: ', error)
    }
  }

  const handleUpdateUserNames = async () => {
    const payload = {
      firstName: firstName,
      lastName: lastName,
      resetPassword: false
    } as PatchUserPayload

    try {
      const response = await patchUser(user.id, payload)
      if (response && response.status === 200) {
        triggerPageRefresh()
        window.alert('succesfully updated names')
      } else {
        window.alert('failed to update names')
      }
    } catch (error) {
      console.error('failed to update user names: ', error)
    }
  }

  const handleUpdateOrgRole = async (oar: UserOrgRole) => {
    setIsRoleModalOpen(true)
    setSelectedOrgRole(oar)
  }

  const handleDeleteOrgRole = async (oldOar: UserOrgRole) => {
    // call the api.
    try {
      const response = await deleteUserOrgRole(oldOar.userEmail, oldOar.orgId)
      if(response && response.status === 200) {
        triggerPageRefresh()
      }
    } catch(error) {
      console.error('failed to delete org role: ', error)
    }
  }

  const handleNewOrgRole = () => {
    setIsRoleModalOpen(true)
  }


  return (
    <div className="table-container">
      <div className="controls">
        <h2>Editing User: {user.id}</h2>
        <Button onClick={handleResetPasswordClick} className="create-button" variant='contained'>Reset Password</Button>
      </div>

      {/* Role Modal */}
      {
        isRoleModalOpen && (
          <RoleModal
            userEmail={user.email}
            orgRole={selectedOrgRole}
            onClose={closeRoleModal}
            refreshTable={triggerPageRefresh}
            orgIdsToExclude={orgsWithRole}
          />
        )
      }
      <div>
        <FormControl className="formControlsContainer">
          <TextField
            label='First Name'
            InputLabelProps={{ shrink: true }}
            onChange={handleFirstNameChange}
            variant="outlined"
            value={firstName}
          />
        </FormControl>
        <FormControl className="formControlsContainer">
          <TextField
            label='Last Name'
            InputLabelProps={{ shrink: true }}
            onChange={handleLastNameChange}
            variant="outlined"
            value={lastName}
            sx={{
              marginLeft: '10px'
            }}
          />
        </FormControl>
        <Button 
          className="create-user-button" 
          variant="contained" 
          onClick={handleUpdateUserNames}
          sx={{
            marginLeft: '10px',
            marginTop: '10px'
          }}
        >
          {'Update User Names'}
        </Button>
      </div>
      
      {user.id !== undefined ?
        <table>
          <thead>
            <div>
              <h2>User Org Roles</h2>
              <Button 
                onClick={handleNewOrgRole} 
                className="create-user-button" 
                variant='contained'
                sx={{
                  marginBottom: '8px'
                }}
              >Create New Org Role</Button>
            </div>
            <tr>
              <th>Org ID</th>
              <th>Roles</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {user.orgsAndRoles.map((oar) => {
              return (
                <tr key={oar.orgId}>
                  <td>
                    <span className="cell-text">{oar.orgId}</span>
                  </td>
                  <td>
                    <span className="cell-text">{oar.roles.map((role) => role).join(', ')}</span>
                  </td>
                  <td>
                    <IconButton aria-label="edit" onClick={() => {
                      handleUpdateOrgRole(oar)
                    }} className='icon-button'>
                      <Image
                        alt={'Edit Icon'}
                        src={editIcon}
                      />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => {
                      handleDeleteOrgRole(oar)
                    }} className='icon-button'>
                      <Image
                        alt={'Delete Icon'}
                        src={deleteIcon}
                      />
                    </IconButton>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table> :
        <></>
      }
    </div>

  );
};

export default EditUser;