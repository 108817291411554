import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {iamServiceURL} from "../config"
import { UserOrgRole } from 'src/types/users'

export type DeleteUserOrgRole = (userEmail: string, orgId: string) => Promise<AxiosResponse<UserOrgRole> | undefined>

export const deleteUserOrgRole: DeleteUserOrgRole = async (userEmail: string, orgId: string) => {
  try {
    const path = `/v3/users/org-role?userEmail=${userEmail}&orgId=${orgId}`
    const headers = {
      'Content-Type': 'application/json'
    }

    const opts = {
      baseURL: iamServiceURL,
      headers: headers,
    }

    return await axiosInstance.delete<UserOrgRole>(path, opts)
  } catch (error) {
    console.error('Error deleting org role:', error)
    throw error
  }
}